import React from 'react'
import InnerHaeder from '../Component/InnerHaeder'
import Highlights from '../Component/Highlights'
import Footer from'../Component/Footer'
import { Link } from 'react-router-dom'
import HeadTag from '../Component/HeadTags'
const ExperientialLearning = () => {
  return (
    <>
    <HeadTag title="Experiential Learning" />
     <Highlights/>
     <InnerHaeder/>
     <div className="inner_page">
       <div className="container-fluid flwidth">
       <div className="bgimage">
        <h5><Link to="/"> Home <i className="fas fa-angle-right"></i> </Link><span> Academics <i className="fas fa-angle-right"></i>  Experiential Learning </span></h5>
        <h2> Experiential Learning</h2>
        </div>
        <div className="row">
            <div className="col-lg-12 col-md-12">
            <div className="col-md-12">
            <h3>Discovering India Workshop</h3>
            <p><strong>'The real voyage of discovery consists not in seeking new landscapes, but in having new eyes.’</strong></p>
            <p>Celebrating the rich heritage and vibrant culture of India, LVIS, NE organised a workshop ‘The Soul of Incredible India - Odisha’ on Saturday, 11 December 2021, for the learners of grade III.
              Pursuing the vision of the school Principal, Ms. Indu Yadav and in concurrence with the CBSE roadmap, Discovering India is a programme that inspires young learners to explore the treasures of Incredible India. This workshop contributed immensely in providing the learners to experience the culture of the state of the month- Odisha.</p>
            <p>Odisha was charmingly introduced by Ms. Nimisha, the talented dance facilitator. Through her mesmerizing Odissi dance performance, she spellbound the audience with her aesthetic costume, hast mudras and graceful expressions.</p>
            <p>Next, the guest speaker, Ms. Aparnashree Mishra, a parent of LVIS NE and a native of Sambalpur district of Western Odisha, nostalgically articulated the geography and history of Odisha. She spoke about the enchanting beauty of the untouched landscapes, the traditions behind famous festivals like the Rath Yatra, Pipli handicrafts, the folk dance and famed Sambalpuri Sarees. This glorious overview enriched learners with the unparalleled grandeur of the state.</p>
            <p>While sharing some of the traditional delicacies from the state, Ms Aparnashree demonstrated an interesting recipe of Chhena Poda which the learners enthusiastically prepared along with her.</p>
            <p>An inspiring message from the principal, Ms Indu Yadav urged the young lotusians to continue their exploration of the uniqueness that their country offers.</p>
            <div className="clr"></div>
            <h3>Discovering India</h3>
            <p><strong>"India is the one land that all men desire to see, and having seen once, by even a glimpse, would not give that glimpse for all the shows of all the rest of the globe combined.""</strong></p>
            <p>Our country is a rich tapestry of culture, traditions and valiant folklore. To celebrate the rich diversity and culture of this great nation, ‘Discovering India Week’ was organised for the learners of the Primary Wing of Lotus Valley International School, Noida Extension, from 9 to 13 August, 2021.</p>
            <p>As citizens of this great nation, learners of the Primary Wing embarked on a journey to discover the affluent heritage and strive to uphold the values that have been passed on through generations, each day. The week was privy to celebrations of cultures, values and traditions that together contribute to the secularism of India. The discovering India week was rife with a multitude of activities including virtual tours to historical sites and monuments, and local traditions and cultures surrounding cuisines, music, dance and attire. They indulge in activities to highlight the local craftsmanship of the states of Punjab, Rajasthan and Maharashtra, like Warli painting, blue pottery, etc. The students also donned the hats of chefs to recreate famous local delicacies in fireless cooking activities. To culminate the week of celebration, they took on the roles of famous people from all across India in the stirring and spirited character parade. Parents, grandparents and students performed folk dances and celebrated the cultural fest.</p>
            <p>This week of celebration and discovery of India brought to light the patriotic spirit that is instilled in each one of us, and the happiness of being part of a great legacy.</p>
            <div className="clr"></div>
            <h3>International Yoga Day</h3>
            <p><strong>'Yoga is the artwork of awareness on the canvas of body, mind and soul'</strong></p>
            <p>Yoga is an invaluable gift of India to the world. It embodies the unity of mind and body and thoughts and actions. Students, parents and teachers of LVIS, NE came together to celebrate the 7th International Yoga Day with a virtual yoga session led by the school’s yoga instructors Ms. Jyoti and Ms. Sulochana. The audience performed various asanas like suryanamaskara, dhanurasana, ardha ushtrasana and pranayama while also discovering the benefits of each asana promoting good health and well being.</p>
            <p>The session was a reminder of the ability of this traditional form of exercise to optimally blend physical and mental disciplines in a way that alleviates stress and improves vitality. The school Principal Ms. Indu Yadav, in her welcome address, reverberated the need to be mindful about our lifestyles and to create a consciousness that pledges a system of harmony between man and nature.</p>
            <p>As the session drew to a close, each member of the audience found themselves to be peaceful, composed and in-sync with their inner self.</p>
            <div className="clr"></div>
            <h3>Student Led Conference</h3>
            <p>In our endeavour to ignite the spirit of inquiry and enable learners to communicate confidently as future global ambassadors , LVIS ,NE organised its first ever Student Led Parent Conference during the Nature Nurture week on Friday, 23rd April 2021. The students of grades IV-V led the conference with panache and poise. Our collaborative partners , parents were invited and young environmentalists put across their concerns and questions related to the prevailing climate issues. The parents addressed each of these questions, welcoming different perspectives and widening the scope of discussion for all . Apart from enhancing the student’s critical thinking skills, this synergy added value in identifying the environmental issues, ways to mitigate them at individual level and think of the bigger picture.</p>
            <div className="clr"></div>
            <h3>World Book Day</h3>
            <p><strong>"Books are the quietest and most constant of friends; they are the most accessible and wisest of counsellors, and the most patient of teachers."</strong></p> 
    <p>Books are the gifts that one must not forget to open time and again. Lotus Valley International School, Noida Extension celebrated the World Book Day on April 23, 2021 to kindle the love for reading amongst the learners. On April 23, UNESCO too celebrates World Book Day which also marks the death anniversary of the literary giant William Shakespeare. Commemorating the event, the young readers of LVIS,NE, participated in different activities like ‘Bookmark Designing’ with motivational quotes, ‘Book Cover Designing’ and ‘Summary Narration’ with twisted endings. The school’s relentless efforts to promote reading paid off as not just the students but also many parents felt motivated and chose to squeeze time out of their busy schedules to read with their young ones. The Weekly Book Discussion activity was initiated on this day wherein students along with their class teachers enthusiastically attended Pre-Reading activities based on the supplementary books prescribed to them. Children felt transported to a different world, experiencing the joy of travelling without moving out of the comfort of their houses while they read their books.</p> 
              
          </div>
            </div>
        </div>
        </div>
    </div>
     <Footer/>
    </>
  )
}

export default ExperientialLearning
