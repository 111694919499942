import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom"; // Import useNavigate hook
import { getSubGallery } from "../Service/Api";
import InnerHaeder from '../Component/InnerHaeder'
import Highlights from '../Component/Highlights'
import Footer from '../Component/Footer' 
import HeadTag from '../Component/HeadTags'
const SubGallery = () => {
   const navigate = useNavigate();
   const location = useLocation();
   const id = new URLSearchParams(location.search).get("id");
   const [data, setData] = useState("");
   const [index, setIndex] = React.useState(-1);
 
   console.log(id);
 
   useEffect(() => {
     const getData = async () => {
       try {
         let subgalleryData = await getSubGallery(id);
         console.log(subgalleryData);
         setData(subgalleryData);
       } catch (error) {
         console.log(error);
       }
     };
     getData();
   }, []);
 
   console.log(data);
 
   const slides = data?.attachments?.map(img => ({
     src: `https://d280nq1n4mqyso.cloudfront.net/api/image/${img}`
   }));
   return (
      <>
      <HeadTag title="Photo Category" />
         <Highlights />
         <InnerHaeder />
         <div className="inner_page">
            <div className="container-fluid flwidth gallery_sec_inner_cate">
               <div className="bgimage">
                  <h5><Link to="/"> Home <i className="fas fa-angle-right"></i> </Link><span>  Gallery <i className="fas fa-angle-right"></i>   Photo Gallery  <i className="fas fa-angle-right"></i>  Photo Category   </span></h5>
                  <h2>{data.title}</h2>
               </div>
               <div class="row">
                  <div class="col-lg-3 col-md-6">
                     <h4>Last Update : <span> {new Date(data.date).toLocaleDateString("en-GB")}</span> </h4>
                  </div>
                  <div class="col-lg-3 col-md-6">
                     <h4>Event Date : <span> {new Date(data.date).toLocaleDateString("en-GB")}</span> </h4>
                  </div>
                  <div class="col-lg-3 col-md-6">
                     <h4>No. of Photos :<span> {data?.attachments?.length}</span></h4>
                  </div>
                  <div class="col-lg-3 col-md-6">
                     <h4><center><Link to="/PhotoGallery"><i class="fa fa-angle-double-left" aria-hidden="true"></i> Click here to Go Back  </Link></center></h4>
                  </div>
               </div>

               <div class="row">
                  <div class="col-lg-12">
                     <p>{data.description}</p>
                  </div>
               </div>
               <div class="row"> {data !== "" &&
              data?.attachments.map((img,index) => (
                  <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3 galleryCount sub-gallerycount">
                     <a data-magnify="gallery" data-src="" data-group="a" href={`https://d280nq1n4mqyso.cloudfront.net/api/image/${img}`}><img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${img}`} class="img-fluid" alt="" /></a>
                  </div>))} 
                  {/* <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3 galleryCount sub-gallerycount">
                     <a data-magnify="gallery" data-src="" data-group="a" href="images/common-img-1.jpg"><img src="images/common-img-1.jpg" class="img-fluid" alt="" /></a>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3 galleryCount sub-gallerycount">
                     <a data-magnify="gallery" data-src="" data-group="a" href="images/common-img-1.jpg"><img src="images/common-img-1.jpg" class="img-fluid" alt="" /></a>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3 galleryCount sub-gallerycount">
                     <a data-magnify="gallery" data-src="" data-group="a" href="images/common-img-1.jpg"><img src="images/common-img-1.jpg" class="img-fluid" alt="" /></a>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3 galleryCount sub-gallerycount">
                     <a data-magnify="gallery" data-src="" data-group="a" href="images/common-img-1.jpg"><img src="images/common-img-1.jpg" class="img-fluid" alt="" /></a>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3 galleryCount sub-gallerycount">
                     <a data-magnify="gallery" data-src="" data-group="a" href="images/common-img-1.jpg"><img src="images/common-img-1.jpg" class="img-fluid" alt="" /></a>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3 galleryCount sub-gallerycount">
                     <a data-magnify="gallery" data-src="" data-group="a" href="images/common-img-1.jpg"><img src="images/common-img-1.jpg" class="img-fluid" alt="" /></a>
                  </div> */}
               </div>
            </div>
         </div>
         <Footer />
      </>
   )
}

export default SubGallery
