import React from 'react'
import InnerHaeder from '../Component/InnerHaeder'
import Highlights from '../Component/Highlights'
import Footer from'../Component/Footer'
import { Link } from 'react-router-dom'
import HeadTag from '../Component/HeadTags'
const Infrastructure = () => {
  return (
    <>
    <HeadTag title="Infrastructure" />
     <Highlights/>
     <InnerHaeder/>
     <div className="inner_page">
       <div className="container-fluid flwidth">
       <div className="bgimage">
        <h5><Link to="/"> Home <i className="fas fa-angle-right"></i> </Link><span>   Infrastructure</span></h5>
        <h2> Infrastructure </h2>
        </div>
        <div className="row img_data_sec">
        <div className="col-lg-4 col-md-6"><div className="box"> <a data-magnify="gallery" data-src="" data-group="a" href="Images/CLASSROOM.jpg">
            <img src="Images/CLASSROOM.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/></a><h4>CLASSROOM</h4></div></div> 
          <div className="col-lg-4 col-md-6"><div className="box"> <a data-magnify="gallery" data-src="" data-group="a" href="Images/ICT-LAB.jpg">
              <img src="Images/ICT-LAB.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/></a><h4>ICT LAB</h4></div></div> 
          <div className="col-lg-4 col-md-6"><div className="box"> <a data-magnify="gallery" data-src="" data-group="a" href="Images/INFIRMARY.jpg">
              <img src="Images/INFIRMARY.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/></a><h4>INFIRMARY</h4></div></div> 
          <div className="col-lg-4 col-md-6"><div className="box"> <a data-magnify="gallery" data-src="" data-group="a" href="Images/CAFETERIA.jpg">
              <img src="Images/CAFETERIA.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/></a><h4>CAFETERIA</h4></div></div> 
          <div className="col-lg-4 col-md-6"><div className="box"> <a data-magnify="gallery" data-src="" data-group="a" href="Images/OPEN AIR AMPHITHEATRE.jpg">
              <img src="Images/OPEN AIR AMPHITHEATRE.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/></a><h4>OPEN AIR AMPHITHEATRE</h4></div></div> 
          <div className="col-lg-4 col-md-6"><div className="box"> <a data-magnify="gallery" data-src="" data-group="a" href="Images/JUNGLE GYM.jpg">
              <img src="Images/JUNGLE GYM.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/></a><h4>JUNGLE GYM</h4></div></div> 
         <div className="col-lg-4 col-md-6"><div className="box"> <a data-magnify="gallery" data-src="" data-group="a" href="Images/Biology-Lab.jpg">
            <img src="Images/Biology-Lab.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/></a><h4>BIOLOGY LAB</h4></div></div> 
         <div className="col-lg-4 col-md-6"><div className="box"> <a data-magnify="gallery" data-src="" data-group="a" href="Images/Chemistry-Lab.jpg">
            <img src="Images/Chemistry-Lab.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/></a><h4>CHEMISTRY LAB</h4></div></div> 
         <div className="col-lg-4 col-md-6"><div className="box"> <a data-magnify="gallery" data-src="" data-group="a" href="Images/Physics-Lab.jpg">
            <img src="Images/Physics-Lab.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/></a><h4>PHYSICS LAB</h4></div></div> 
        <div className="col-lg-4 col-md-6"><div className="box"> <a data-magnify="gallery" data-src="" data-group="a" href="Images/SPLASH POOL.jpg">
            <img src="Images/SPLASH POOL.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/></a><h4>SPLASH POOL</h4></div></div> 
        <div className="col-lg-4 col-md-6"><div className="box"> <a data-magnify="gallery" data-src="" data-group="a" href="Images/Library.jpg">
            <img src="Images/Library.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/></a><h4>Library</h4></div></div>   
        <div className="col-lg-4 col-md-6"><div className="box"> <a data-magnify="gallery" data-src="" data-group="a" href="Images/Astronomy.JPG">
            <img src="Images/Astronomy.JPG" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/></a><h4>Astronomy</h4></div></div>   
        <div className="col-lg-4 col-md-6"><div className="box"> <a data-magnify="gallery" data-src="" data-group="a" href="Images/basket-ball.JPG">
            <img src="Images/basket-ball.JPG" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/></a><h4>Basket Ball</h4></div></div>  
        <div className="col-lg-4 col-md-6"><div className="box"> <a data-magnify="gallery" data-src="" data-group="a" href="Images/chess.JPG">
            <img src="Images/CHESS.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/></a><h4>Chess</h4></div></div>  
        <div className="col-lg-4 col-md-6"><div className="box"> <a data-magnify="gallery" data-src="" data-group="a" href="Images/computer-lab.JPG">
            <img src="Images/computer-lab.JPG" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/></a><h4>Computer Lab</h4></div></div>  
        <div className="col-lg-4 col-md-6"><div className="box"> <a data-magnify="gallery" data-src="" data-group="a" href="Images/Football.JPG">
            <img src="Images/FOOTBALL.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/></a><h4>Football</h4></div></div> 
        <div className="col-lg-4 col-md-6"><div className="box"> <a data-magnify="gallery" data-src="" data-group="a" href="Images/lawn-tennis.JPG">
            <img src="Images/lawn-tennis.JPG" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/></a><h4>Lawn Tennis</h4></div></div>
        <div className="col-lg-4 col-md-6"><div className="box"> <a data-magnify="gallery" data-src="" data-group="a" href="Images/Open-Library.JPG">
            <img src="Images/Open-Library.JPG" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/></a><h4>Open Library</h4></div></div>
        <div className="col-lg-4 col-md-6"><div className="box"> <a data-magnify="gallery" data-src="" data-group="a" href="Images/Skating.JPG">
            <img src="Images/SKATING.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/></a><h4>Skating</h4></div></div>
        <div className="col-lg-4 col-md-6"><div className="box"> <a data-magnify="gallery" data-src="" data-group="a" href="Images/STEAM.JPG">
            <img src="Images/STEAM.JPG" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/></a><h4>Steam</h4></div></div>
        <div className="col-lg-4 col-md-6"><div className="box"> <a data-magnify="gallery" data-src="" data-group="a" href="Images/Swimming.jpg">
            <img src="Images/Swimming.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/></a><h4>Swimming</h4></div></div>
        <div className="col-lg-4 col-md-6"><div className="box"> <a data-magnify="gallery" data-src="" data-group="a" href="Images/Table-Tennis.JPG">
            <img src="Images/Table-Tennis.JPG" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/></a><h4>Table Tennis</h4></div></div>
        <div className="col-lg-4 col-md-6"><div className="box"> <a data-magnify="gallery" data-src="" data-group="a" href="Images/Taekwondo.jpg">
            <img src="Images/Taekwondo.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/></a><h4>Taekwondo</h4></div></div>
          </div>
        </div>
    </div>
     <Footer/> 
    </>
  )
}

export default Infrastructure
