import React, { useState } from 'react';
import InnerHaeder from '../Component/InnerHaeder';
import Highlights from '../Component/Highlights';
import Footer from '../Component/Footer';
import { Link } from 'react-router-dom';
import HeadTag from '../Component/HeadTags';

const Media = () => {
  const [selectedYear, setSelectedYear] = useState('2024');
  const [selectedMonth, setSelectedMonth] = useState('');

  const years = ['2024', '2020', '2018'];
  const months = [
    { name: 'January', value: '01' },
    { name: 'February', value: '02' },
    { name: 'March', value: '03' },
    { name: 'April', value: '04' },
    { name: 'May', value: '05' },
    { name: 'June', value: '06' },
    { name: 'July', value: '07' },
    { name: 'August', value: '08' },
    { name: 'September', value: '09' },
    { name: 'October', value: '10' },
    { name: 'November', value: '11' },
    { name: 'December', value: '12' }
  ];

  const mediaData = [
    { id: 1, title: ' ', date: '2024-09-17', imgSrc: 'Images/media17-9-2024-1.jpg' },
    { id: 2, title: ' ', date: '2024-09-17', imgSrc: 'Images/media17-9-2024-2.jpg' },
    { id: 3, title: ' ', date: '2024-09-17', imgSrc: 'Images/media17-9-2024-3.jpg' },
    { id: 4, title: 'Annual Day', date: '2020-03-02', imgSrc: 'Images/Media-03-03-2020-2.jpg' },
    { id: 5, title: 'Sports & Culture', date: '2020-01-27', imgSrc: 'Images/Media-03-03-2020-3.jpg' },
    { id: 6, title: 'Lotus Valley', date: '2018-12-24', imgSrc: 'Images/Media-04-02-19.jpg' },
    { id: 7, title: 'Orientation Day', date: '2018-03-28', imgSrc: 'Images/Media.jpg' },
    { id: 8, title: 'School Buzz', date: '2020-03-03', imgSrc: 'Images/Media-03-03-2020-1.jpg' }
  ];

  // Filter media items based on selected year and month
  const filteredMedia = mediaData.filter((item) => {
    const mediaYear = item.date.split('-')[0];
    const mediaMonth = item.date.split('-')[1];
    return (
      (!selectedYear || mediaYear === selectedYear) &&
      (!selectedMonth || mediaMonth === selectedMonth)
    );
  });

  // Get available months based on the selected year
  const availableMonths = months.filter((month) =>
    mediaData.some((item) => {
      const mediaYear = item.date.split('-')[0];
      const mediaMonth = item.date.split('-')[1];
      return mediaYear === selectedYear && mediaMonth === month.value;
    })
  );

  return (
    <>
      <HeadTag title="Media" />
      <Highlights />
      <InnerHaeder />
      <div className="inner_page">
        <div className="container-fluid flwidth">
          <div className="bgimage">
            <h5>
              <Link to="/"> Home <i className="fas fa-angle-right"></i> </Link>
              <span> Gallery <i className="fas fa-angle-right"></i> Media </span>
            </h5>
            <h2>Media</h2>
          </div>

          {/* Year Selection */}
          <div className="filter-section">
            <div className="row">
              
            <div className="col-md-9">
            {availableMonths.length > 0 && (
              <div className="month-tabs">
                <ul className="nav nav-tabs">
                  <li className={`nav-item ${selectedMonth === '' ? 'active' : ''}`}>
                    <button
                      className="nav-link"
                      onClick={() => setSelectedMonth('')}
                    >
                      All Months
                    </button>
                  </li>
                  {availableMonths.map((month) => (
                    <li
                      key={month.value}
                      className={`nav-item ${selectedMonth === month.value ? 'active' : ''}`}
                    >
                      <button
                        className="nav-link"
                        onClick={() => setSelectedMonth(month.value)}
                      >
                        {month.name}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            )}
              </div>
              <div className="col-md-3"> 
                <select value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)} className="form-control">
                  {years.map((year, index) => (
                    <option key={index} value={year}>
                    Year   {year}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/* Month Tabs */}
          
          </div>

          <div className="row img_data_sec">
            {filteredMedia.length > 0 ? (
              filteredMedia.map((item) => (
                <div className="col-xl-3 col-lg-4 col-md-6" key={item.id}>
                  <div className="box">
                    <a data-magnify="gallery" data-src="" data-group="a" href={item.imgSrc}>
                      <img src={item.imgSrc} className="img-fluid" alt={item.title} />
                    </a>
                    <h4>{item.title}</h4>
                  </div>
                </div>
              ))
            ) : (
              <div className="col-md-12">
                <p>No media found for the selected filters.</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Media;
