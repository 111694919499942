import React from 'react'
import InnerHaeder from '../Component/InnerHaeder'
import Highlights from '../Component/Highlights'
import {SlideshowLightbox} from 'lightbox.js-react'
import Footer from'../Component/Footer'
import { Link } from 'react-router-dom'
import HeadTag from '../Component/HeadTags'
const ERPSoftware = () => {
  return (
    <>
    <HeadTag title="ERP Software" />
     <Highlights/>
     <InnerHaeder/>
     <div className="inner_page">
       <div className="container-fluid flwidth">
       <div className="bgimage">
        <h5><Link to="/"> Home <i className="fas fa-angle-right"></i> </Link><span>  ERP Software  </span></h5>
        <h2> ERP Software </h2>
        </div>
        <div className="innerpage">
        <div className="row erp_sec">
        <div className="col-12 col-sm-12 col-md-9">
                     <div className="row">
                     <div className="col-12 col-sm-12 col-md-12">
                     <p className="details"> Entab Infotech Pvt Ltd., a company formed in 2000 and serving the school with automation technologies has evolved out as the best service provider through their advanced <Link to="https://www.entab.in/school-management-erp.html"> <b>ERP Software:</b></Link> 'CampusCare®' and communication tools. We are also proud to be associated with them for managing the data of students, exam, fees, staff and  their accounts etc in addition to the <Link to="https://www.entab.in/school-communication-portals.html"> <b> communication portal</b></Link> and <Link to="https://www.entab.in/mobile-apps.html"> <b> mobile apps</b></Link>.<br/>
                     <br/>
                     The entire system is strengthening the academic of the students and preparing responsible staff and students. Together the reputation of the school grows higher. We value the relation with  <Link to="https://www.entab.in/"> <b>Entab: CampusCare®</b></Link> and look forward to receive the much more in technological front.</p>
                      </div>
                     </div>
                     <div className="clr25"></div>
                     <div className="row"> 
                          
                          <div className="col-12 col-sm-4 col-md-4">
                               <h4 className="imgheading"><Link to="https://www.entab.in/school-management-erp.html"> Manage Data</Link></h4>
                               
                               <SlideshowLightbox><img id="myImg" src="Images/Manage-Data.gif" className="img-fluid" alt="School Management System, Management ERP Software"/></SlideshowLightbox>
                              
                               
                          </div>
                          <div className="col-12 col-sm-4 col-md-4">
                               <h4 className="imgheading"><Link to="https://www.entab.in/school-communication-portals.html#PPC"> Parent Portal</Link></h4>
                               
                                                        <SlideshowLightbox><img id="myImg1" lightbox src="Images/Parent-Portal.gif" className="img-fluid" alt="Entab Parent Portal, School Software"/></SlideshowLightbox>
                               
                          </div>
                          <div className="col-12 col-sm-4 col-md-4">
                               <h4 className="imgheading"><Link to="https://www.entab.in/school-communication-portals.html#PPC"> Communication Portal</Link></h4>
                               <SlideshowLightbox><img id="myImg2" src="Images/communication.gif"  className="img-fluid" alt="Entab Parent Portal"/></SlideshowLightbox>
                                                        
                          </div>
                     </div>
                </div>
        <div className="col-12 col-sm-12 col-md-3">
                     <h4 className="imgheading"><Link to="https://www.entab.in/mobile-apps.html">CampusCare</Link></h4>
                    <center><Link to="https://www.entab.in/mobile-apps.html"><img src="Images/Mobile-slides.gif" className="img-fluid" alt="School ERP Software, Mobile App, School Management Software, Software for School"/></Link> </center>
                    </div>
        </div> 
        </div>
        </div>
    </div>
     <Footer/>
    </>
  )
}

export default ERPSoftware
