import { useEffect, useState } from "react";
import { getCircular } from "../Service/Api";
import InnerHaeder from '../Component/InnerHaeder';
import Highlights from '../Component/Highlights';
import Footer from'../Component/Footer';
import { Link } from 'react-router-dom';
import HeadTag from '../Component/HeadTags'

const Circulars = () => { 
  const [data, setData] = useState([]);
  const [filterData, setFilteredData] = useState([]);
  const [selectedYear, setSelectedYear] = useState();
  const [yearRanges, setYearRanges] = useState([]); 

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const Year = `${currentYear}`;
    setSelectedYear(Year);
    console.log("get current Year", Year);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const circularData = await getCircular();
      setData(circularData);
      const circularYears = extractYears(circularData);
      setYearRanges(circularYears);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (data.length === 0) {
      return;
    }
  
    let filteredData = data.filter((item) => item.category === "Circular");
    let currentFilteredData = filterDataByYear(filteredData, selectedYear);
  
    // If no data for the selected year, fallback to previous year with data
    if (currentFilteredData.length === 0) {
      const availableYears = yearRanges;
      for (let i = 0; i < availableYears.length; i++) {
        const fallbackYear = availableYears[i];
        currentFilteredData = filterDataByYear(filteredData, fallbackYear);
        if (currentFilteredData.length > 0) {
          setSelectedYear(fallbackYear); // Update selected year to the fallback year
          break;
        }
      }
    }
  
    // Sort the currentFilteredData by date in descending order (latest on top)
    currentFilteredData.sort((a, b) => new Date(b.date) - new Date(a.date));
  
    setFilteredData(currentFilteredData);
  }, [selectedYear, data, yearRanges]);
  
  

  const filterDataByYear = (data, year) => {
    if (year === "All") return data;
    return data.filter((item) => {
      if (item.date && item.date.trim() !== "") {
        const itemYear = new Date(item.date).getFullYear();
        return parseInt(year) === itemYear;
      }
      return false;
    });
  };

  const extractYears = (data) => {
    const years = data
      .filter((item) => item.category === "Circular")
      .map((item) => {
        if (item.date && item.date.trim() !== "") {
          return new Date(item.date).getFullYear().toString();
        }
        return null;
      })
      .filter((year, index, self) => year && self.indexOf(year) === index)
      .sort((a, b) => b - a); // Sort years in descending order
    return years;
  };

  return (
    <>
      <HeadTag title="Circulars" /> 
      <Highlights/>
      <InnerHaeder/>
      <div className="inner_page">
        <div className="container-fluid flwidth">
          <div className="bgimage">
            <h5>
              <Link to="/"> Home <i className="fas fa-angle-right"></i> </Link>
              <span> Circulars </span>
            </h5>
            <h2> Circulars</h2>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="chooseyear">
                <select
                  id="chooseddlYear"
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(e.target.value)}
                  className="yearSelection"
                >
                  <option value="All">All</option>
                  {yearRanges.map((yr) => (
                    <option key={yr} value={yr}>{yr}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="row pdf_sec">
                {filterData.length > 0 ? (
                  filterData.map((item, index) => (
                    <div className="col-lg-3 col-md-6" key={index}>
                      <div className="box">
                        <Link to={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item?.attachments[0]}`} target="_blank">
                          <img src="Images/circular.png" className="img-fluid" alt="Circular"/>
                          <h5>{item.date}</h5>
                          <h4>{item.title}</h4>
                        </Link>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="col-lg-3 col-md-6">
                    <div className="box">
                      <Link to="#" target="_blank">
                        <img src="Images/circular.png" className="img-fluid" alt="Circular"/>
                        <h5>dd.mm.yyyy</h5>
                        <h4>Title</h4>
                      </Link>
                    </div>
                  </div>
                )}
              </div> 
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default Circulars;
