import React from 'react'
import InnerHaeder from '../Component/InnerHaeder'
import Highlights from '../Component/Highlights'
import Footer from'../Component/Footer'
import { Link } from 'react-router-dom'
import HeadTag from '../Component/HeadTags'
const OurMotto = () => {
  return (
    <>
    <HeadTag title="Our Motto" />
     <Highlights/>
     <InnerHaeder/>
     <div className="inner_page">
     <div className="container-fluid flwidth">
         <div className="bgimage">
     <h5><Link to="/"> Home <i className="fas fa-angle-right"></i> </Link><span> About Us  <i className="fas fa-angle-right"></i> Our Motto </span></h5>
     <h2>Our Motto</h2>
      <div className="clr"></div></div>
      <p><img src="Images/emblem.jpg" className="inner_img img-fluid" alt="Lotus Valley International School, Greater Noida"/></p>
      <p><strong>"Arise, awake and stop not till the goal is reached."</strong></p>
      <p>The motto of the school <strong>'Arise Awake and Stop Not Till The Goal is Reached'</strong>, taken from the clarion call of Swami Vivekanand to the youth of the nation, sums up the philosophy of Lotus Valley International School. The objective of the school is to assist the students to unfold their latent talents and to help them to grow to the highest possible level in as many fields as is possible for each individual. In addition to facilitating their physical, mental, spiritual, aesthetic and ethical growth, the school invests them with an indomitable spirit. <br/>
               Our training here is geared to helping them discover their infinite, and expandable worth as they grow up. They will not necessarily look for external approval – they will be spurred into action by an internal drive. </p>
             <p>Our constant endeavour is to develop communication skills, leadership qualities and life skills such as problem solving, realistic goal setting, self-confidence, decision making, independent thought etc. </p>
              <p>The School will be organized along the house system, to facilitate the development of moral and spiritual values in in keeping with the secular character of the school and the values of service and citizenship. It will  endeavour to inculcate not only humanitarian values to help the needy and the deprived but also concern and commitment towards the improvement of the environment and the welfare of animals. Recognizing the vast kaleidoscope of diversity and heterogeneity, the students will be taught to appreciate their role as citizens of the world and directly involve themselves with issues that are of common interest to them and to the rest of the world. </p>
             <p>Although high academic achievement is undoubtedly our endeavour, our goal is the development of the child's total personality - self-discipline, self-reliance, alertness, team work, concern for others, to be in harmony with nature. 
     
             </p> <div className="clr"></div> 
      </div>
     </div>
     <Footer/>
    </>
  )
}

export default OurMotto
