import React from 'react'
import InnerHaeder from '../Component/InnerHaeder'
import Highlights from '../Component/Highlights'
import Footer from'../Component/Footer'
import { Link } from 'react-router-dom'
import HeadTag from '../Component/HeadTags'
const SchoolTimings = () => {
  return (
    <>
    <HeadTag title="School Timings" />
     <Highlights/>
     <InnerHaeder/>
     <div className="inner_page">
       <div className="container-fluid flwidth">
       <div className="bgimage">
        <h5><Link to="/"> Home <i className="fas fa-angle-right"></i> </Link><span>   School Timings </span></h5>
        <h2> School Timings</h2>
        </div>
        <div className="row">
            <div className="col-lg-12 col-md-12">
               <img src="Images/timing.jpg" className="art_craft_img img-fluid mx-auto d-block"  alt="Lotus Valley International School, Greater Noida"/><br/>
               <p className="text-center"><strong>Nursery - KG :</strong> - 9:00 am to 12:30 pm</p>
               <p className="text-center"><strong>I - XII</strong> - 8:00 am to 2:30 pm </p> 
            </div>
        </div>
        </div>
    </div>
     <Footer/>
    </>
  )
}

export default SchoolTimings
