import React from 'react'
import InnerHaeder from '../Component/InnerHaeder'
import Highlights from '../Component/Highlights'
import Footer from'../Component/Footer'
import { Link } from 'react-router-dom'
import HeadTag from '../Component/HeadTags'
const OurEmblem = () => {
  return (
    <>
    <HeadTag title="Our Emblem" />
     <Highlights/>
     <InnerHaeder/>
     <div className="inner_page">
     <div className="container-fluid flwidth">
       <div className="bgimage">
        <h5><Link to="/"> Home <i className="fas fa-angle-right"></i> </Link><span> About Us  <i className="fas fa-angle-right"></i> Our Emblem </span></h5>
        <h2>Our Emblem</h2>
        </div>
        <p><img src="Images/emblem.jpg" className="inner_img" alt="Lotus Valley International School, Greater Noida"/></p>
        <p>The quarters of the shield represent different aspects of learning: the global outlook necessary for the leaders of tomorrow, the book of knowledge, the torch of hope and integrity that ignites the minds of our young people and lights their path towards excellence and fulfillment, and the veena of Saraswati, the Hindu goddess of learning and of speech, the Flowing One whose origin lies in the lost Vedic river Saraswati.The veena is her musical instrument whose roots lie in antiquity. It is our symbol of creativity, culture and refinement. </p>
        <p>The crest is the lotus of purity and transcendence, flanked by two swans. The 'vahaan' or vehicle of Saraswati is the swan, a bird with the unique ability to separate milk from water, symbolizing the discerning mind that we develop in our students. The blue (symbolizing intellectual achievement) and yellow (for spiritual growth) quartered field of the shield informs us that our curriculum, principles and ethos reflect the unity of East and West.The golden colour of the supporting flags that carry the initials of our school remind us that each member of our school community is unique and irreplaceable and should shine undimmed, like the precious metal itself.</p>
        <p> The motto of the school is derived from the Sanskrit couplet of the Katha Upanishad: <strong>'Uttisthata, Jagrata, Prapya Vara Nibodhata'</strong>, translated by Swami Vivekananda as <strong>'Arise! Awake! And stop not till the goal is reached.'</strong> It serves as our watchword and is a message that will serve all our children well throughout their lives. </p>
        <div className="clr"></div>
        </div>
        </div>
     <Footer/>
    </>
  )
}

export default OurEmblem
