import { useEffect, useState } from "react";
import { getGalleryInner } from "../Service/Api";
import InnerHaeder from '../Component/InnerHaeder';
import Highlights from '../Component/Highlights';
import Footer from'../Component/Footer';
import { useNavigate, Link } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import HeadTag from '../Component/HeadTags'

const PhotoGallery = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [filterData, setFilteredData] = useState([]);
    const [selectedYear, setSelectedYear] = useState();
    const [selectedMonth, setSelectedMonth] = useState("All");
    const [monthOptions, setMonthOptions] = useState(["All"]);
    const [yearRanges, setYearRanges] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");

    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const Year = `${currentYear}`;
        setSelectedYear(Year);
        console.log("get current Year", Year);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const galleryData1 = await getGalleryInner();
            const galleryData = galleryData1.filter(item => item.category === 'PhotoGallery');
            setData(galleryData);

            const yearsSet = new Set();
            const monthsSet = new Set();

            galleryData.forEach(item => {
                if (item.date) {
                    const date = new Date(item.date);
                    yearsSet.add(date.getFullYear());
                    monthsSet.add(date.getMonth());
                }
            });

            const uniqueYearRanges = Array.from(yearsSet).sort((a, b) => b - a);
            setYearRanges(uniqueYearRanges);

            const monthOptions = Array.from(monthsSet).sort((a, b) => a - b).map(month => monthNames[month]);
            setMonthOptions(["All", ...monthOptions]);
        };

        fetchData();
    }, []);

    useEffect(() => {
        let filteredData = data;

        if (selectedYear !== "All") {
            filteredData = filteredData.filter(item => {
                const itemDate = item.date?.trim();
                if (itemDate) {
                    const date = new Date(itemDate);
                    const year = date.getFullYear();
                    const month = date.getMonth();
                    return year === parseInt(selectedYear) && (selectedMonth === "All" || monthNames[month] === selectedMonth);
                }
                return false;
            });
        } else if (selectedMonth !== "All") {
            filteredData = filteredData.filter(item => {
                const itemDate = item.date?.trim();
                if (itemDate) {
                    const month = new Date(itemDate).getMonth();
                    return monthNames[month] === selectedMonth;
                }
                return false;
            });
        }

        if (searchQuery) {
            filteredData = filteredData.filter(item => item.title.toLowerCase().includes(searchQuery.toLowerCase()));
        }

        // Sort the filtered data by date in descending order
        filteredData.sort((a, b) => new Date(b.date) - new Date(a.date));

        setFilteredData(filteredData);
    }, [selectedYear, selectedMonth, searchQuery, data]);

    const handleModal = id => {
        navigate(`/SubGallery?id=${id}`);
    };

    const formatDate = (isoDate) => {
        const date = new Date(isoDate);
        const day = date.getDate().toString().padStart(2, '0');
        const month = monthNames[date.getMonth()];
        const year = date.getFullYear();
        return `${day} ${month} ${year}`;
    };

    const emptyArray = [
        {
            description: "“Educating the mind without educating the heart is no education at all.” Sacred Heart has been educating boys and girls since 1990; though much has changed over the years our mission remains the same. Sacred Heart is committed to developing students of high academic achievement, intellectual curiosity, and strong moral character. As a result, year after year Sacred Heart sends our respectful, responsible and compassionate young adults, who are well prepared to tackle the challenges that lay ahead. Since arriving at Sacred Heart in 2016, I have had the opportunity to observe this unique institution up close. I have seen its commitment to academic excellence, its dynamic student body, and incredibly committed faculty and a loving community where the students and families are proud and supportive of one another. As I walk down the hallways of the school every day, I can hear the chatter of eager minds; the shouts of excitement from the victorious athletes, the thumb of dancers feed and the sound of melodious voices harmonizing the perpetual energy, movement and enthusiasm permeate the atmosphere. Ours is a school with difference. We value creativity and innovation and strive to nurture them in our students. Sacred Heartians are groomed to become confident, articulate and enlightened young citizens ready to step into the global society with dignity, honour and integrity. Aristotle once said “Educating the mind without educating the heart is no education at all”. Even as we impart education to match the advancement in technology and globalization, we march our children ahead with Sacred Heart ethos of moral values and principles. We Endeavour constantly to instill these qualities in our children. We pride ourselves to help them grow and develop into sensitive, responsible God loving citizens of the future. May the Sacred Heart of Jesus bless us abundantly in all our endeavourers.",
            attachments: ["director.jpg"]
        }
    ];

    return (
        <>
        <HeadTag title="Photo Gallery" />
            <Highlights />
            <InnerHaeder />
            <div className="inner_page">
                <div className="container-fluid flwidth">
                    <div className="bgimage">
                        <h5><Link to="/"> Home <i className="fas fa-angle-right"></i> </Link><span>  Gallery <i className="fas fa-angle-right"></i>   Photo Gallery </span></h5>
                        <h2> Photo Gallery</h2>
                    </div>
                    <div className="row tabs-dynamic">
                        <div className="col-md-3">
                            <div className="count-val">
                                <p>Total Count: {filterData.length}</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="month-selection">
                                <select
                                    value={selectedYear}
                                    onChange={(e) => setSelectedYear(e.target.value)}
                                    className="yearSelection">
                                    <option value="All">All</option>
                                    {yearRanges.map((yr) => (
                                        <option key={yr} value={yr}>{yr}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="month-selection">
                                <select
                                    value={selectedMonth}
                                    onChange={(e) => setSelectedMonth(e.target.value)}
                                    className="monthSelect">
                                    {monthOptions.map((month, index) => (
                                        <option key={index} value={month}>{month}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="searchBox">
                                <input
                                    type="text"
                                    id="myInput"
                                    name="name"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    placeholder="Search Here.."
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row gallery_sec_inner">
                        {filterData.length > 0 ? (
                            filterData.map((item, index) => (
                                <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-6" key={index}>
                                    <div className="photogal">
                                        <div className="gal_view_more">
                                            <a onClick={() => navigate(`/SubGallery?id=${item._id}`)}>
                                                <i className="fas fa-angle-double-right"></i>
                                            </a>
                                        </div>
                                        <a onClick={() => navigate(`/SubGallery?id=${item._id}`)}>
                                            <LazyLoadImage src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item?.attachments[0]}`} alt="Lotus Valley International School, Greater Noida" />
                                        </a>
                                        <h3>{item.title}</h3>
                                        <p>{item.description}</p>
                                        <h4>{formatDate(item.date)}</h4>
                                        <h5>{item?.attachments.length}</h5>
                                    </div>
                                </div>
                            ))
                        ) : (
                            emptyArray.map((data, index) => (
                                <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-6" key={index}>
                                    <div className="photogal">
                                        <div className="gal_view_more">
                                            <Link to="/SubGallery">
                                                <i className="fas fa-angle-double-right"></i>
                                            </Link>
                                        </div>
                                        <Link to="/SubGallery">
                                            <img src="Images/common-img-1.jpg" alt="Lotus Valley International School, Greater Noida" />
                                        </Link>
                                        <h3>Category Name</h3>
                                        <p> </p>
                                        <h4>dd.mm.yyyy</h4>
                                        <h5>3</h5>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default PhotoGallery;
