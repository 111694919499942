import React, { useState } from 'react'
import InnerHaeder from '../Component/InnerHaeder'
import Highlights from '../Component/Highlights'
import Footer from'../Component/Footer'
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Link } from 'react-router-dom'
import HeadTag from '../Component/HeadTags'
const TrainingTrainers = () => {

  return (
    <>
    <HeadTag title="Training Trainers" />
     <Highlights/>
     <InnerHaeder/>
     <div className="inner_page">
       <div className="container-fluid flwidth testi_sec trainers">
        <div className="bgimage">
           <h5><Link to="/"> Home <i className="fas fa-angle-right"></i> </Link><span>  Co-Curricular  <i className="fas fa-angle-right"></i>   Training Trainers</span></h5>
           <h2>Training Trainers</h2>
        </div>
          <Tabs className="Tabs">
            <TabList>
                <Tab>2021 - 2022</Tab>
                <Tab>2020 - 2021</Tab> 
            </TabList>
            <TabPanel>
            <div class="accordion accordion-flush" id="accordionFlushExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingSix">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                   September 
                </button>
              </h2>
              <div id="flush-collapseSix" class="accordion-collapse collapse" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                    <div className="row pdf_sec">
                        <div className="col-lg-3 col-md-6">
                           <div className="box">
                              <Link to="/pdf/Training-Data-september-activity.pdf" target="_blank">
                                   <img src="Images/circular.png" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/>
                                   <h5>September </h5>
                                     Activity
                            </Link>
                          </div>
                        </div>
                      <div className="col-lg-3 col-md-6">
                         <div className="box">
                           <Link to="/pdf/Training-Data-september-nur-II.pdf" target="_blank">
                                 <img src="Images/circular.png" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/>
                                 <h5>September </h5>
                                   Class Pre Nur - II
                           </Link>
                         </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                         <div className="box">
                           <Link to="/pdf/Training-Data-september-III-X.pdf" target="_blank">
                                 <img src="Images/circular.png" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/>
                                 <h5>September </h5>
                                   Class III - X
                          </Link>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
           <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingFive">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                   August 
                </button>
              </h2>
              <div id="flush-collapseFive" class="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                      <div className="row pdf_sec">
                        <div className="col-lg-3 col-md-6"><div className="box">
                             <Link to="/pdf/Training-Data-August-activity.pdf" target="_blank"><img src="Images/circular.png" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/><h5>August </h5>  Activity</Link></div></div>
                        <div className="col-lg-3 col-md-6"><div className="box">
                             <Link to="/pdf/Training-Data-August-nur-II.pdf" target="_blank"><img src="Images/circular.png" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/><h5>August </h5>  Class Pre Nur - II</Link></div></div>
                        <div className="col-lg-3 col-md-6"><div className="box">
                             <Link to="/pdf/Training-Data-August-III-X.pdf" target="_blank"><img src="Images/circular.png" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/><h5>August </h5>  Class III - X</Link></div></div>
          
                      </div>
                </div>
              </div>
            </div>
           <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingFour">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                   July 
                </button>
              </h2>
              <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                     <div className="row pdf_sec">
              <div className="col-lg-3 col-md-6"><div className="box">
                 <Link to="/pdf/Training-Data-july-activity.pdf" target="_blank"><img src="Images/circular.png" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/><h5>July </h5>  Activity</Link></div></div>
            <div className="col-lg-3 col-md-6"><div className="box">
                 <Link to="/pdf/Training-Data-july-nur-II.pdf" target="_blank"><img src="Images/circular.png" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/><h5>July </h5>  Class Pre Nur - II</Link></div></div>
            <div className="col-lg-3 col-md-6"><div className="box">
                 <Link to="/pdf/Training-Data-july-III-X.pdf" target="_blank"><img src="Images/circular.png" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/><h5>July </h5>  Class III - X</Link></div></div>
          
          </div>
                </div>
              </div>
            </div>
           <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingThree">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                   June 
                </button>
              </h2>
              <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                     <div className="row pdf_sec">
                  <div className="col-lg-3 col-md-6"><div className="box">
                 <Link to="/pdf/Training-Data-june-activity.pdf" target="_blank"><img src="Images/circular.png" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/><h5>June </h5>  Activity</Link></div></div>
                  <div className="col-lg-3 col-md-6"><div className="box">
                       <Link to="/pdf/Training-Data-june-nur-II.pdf" target="_blank"><img src="Images/circular.png" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/><h5>June </h5>  Class Pre Nur - II</Link></div></div>
                  <div className="col-lg-3 col-md-6"><div className="box">
                       <Link to="/pdf/Training-Data-june-III-X.pdf" target="_blank"><img src="Images/circular.png" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/><h5>June </h5>  Class III - X</Link></div></div>
                 
                </div>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingTwo">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                  May 
                </button>
              </h2>
              <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                    <div className="row pdf_sec">
                  <div className="col-lg-3 col-md-6"><div className="box">
                 <Link to="/pdf/FEEDBACK-MAY-2021.pdf.pdf" target="_blank"><img src="Images/circular.png" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/><h5>May </h5>  Activity</Link></div></div>
           
          </div>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingOne">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                   April 
                </button>
              </h2>
              <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                     
               <div className="row pdf_sec">
                  <div className="col-lg-3 col-md-6"><div className="box">
                 <Link to="/pdf/FEEDBACK-APRIL-2021.pdf.pdf" target="_blank"><img src="Images/circular.png" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/><h5>April </h5>  Activity</Link></div></div>
           
               </div>
                </div>
              </div>
            </div>
          </div>
            </TabPanel>
            <TabPanel>
            <div class="accordion accordion-flush" id="accordionFlushExample1">
               <div class="accordion-item">
<h2 class="accordion-header" id="flush-headingThrteen1">
<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThrteen1" aria-expanded="false" aria-controls="flush-collapseThrteen1">
 Workshop on 'Big Book Workshop – Pre Primary'
</button>
</h2>
<div id="flush-collapseThrteen1" class="accordion-collapse collapse" aria-labelledby="flush-headingThrteen1" data-bs-parent="#accordionFlushExample1">
<div class="accordion-body">
<div class="circular_sec">
<img src="Images/workshop13.jpg" class="inner_img" alt="Lotus Valley International School, Greater Noida"/>
<p>“Books are a uniquely portable magic.” – Stephen King To emphasise the importance of reading and utilizing the books as a key resource in the classes, a session on Big Book Programme was conducted on Friday, 18 October 2019. The resource person Ms. Neelam Narang from Scholastic enlightened the Pre Primary teachers on the relevance of reading in today’s time and its effective use in teaching various concepts. It began with a mirror reflection activity followed by a discussion on the characteristics of 2-7 years old children. Ms. Narang emphasized the need to mould children to become lifelong readers and encourage their passion towards reading. She made everyone acquainted with the different parts of a Big Book. The key takeaway was creating opportunities for children to think and imagine while reading and giving them enough space to perceive and interpret the story in their own way. The advantages of Big Books over the traditional story books were also discussed. The important aspects of reading aloud such as the change of tone, mood, intensity and pace of reading were emphasized as well. Using these big books in the classroom as a method of integrating various subjects was explained effectively. It was an interactive, engaging and fruitful session with great take aways for the teachers.</p>
</div>
</div>
</div>
</div>
<div class="accordion-item">
<h2 class="accordion-header" id="flush-headingTwel1">
<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwel1" aria-expanded="false" aria-controls="flush-collapseTwel1">
 Workshop on 'Session on Menstruation and Menstrual Hygiene'
</button>
</h2>
<div id="flush-collapseTwel1" class="accordion-collapse collapse" aria-labelledby="flush-headingTwel1" data-bs-parent="#accordionFlushExample1">
<div class="accordion-body">
<div class="circular_sec">
<img src="Images/workshop12.jpg" alt="LVISGN" class="inner_img"/>
<p>A session on menstruation and menstrual hygiene was conducted for students of grades VI to VIII by Dr. Kanika Gera, Consultant Gynecologist, Fortis Hospital, Noida. The session gave children an opportunity to understand how and why females menstruate. The speaker also gave students some useful insights into maintaining good hygiene during menstruation, potential risks of keeping poor hygiene during menstruation and ways to effectively and confidently manage the first period. The session helped the children break the taboo around the subject and raise their concerns freely.</p>

</div>
</div>
</div>
</div>
<div class="accordion-item">
<h2 class="accordion-header" id="flush-headingElev1">
<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseElev1" aria-expanded="false" aria-controls="flush-collapseElev1">
 Workshop on 'Author Speak by Ms Nandana Dev Sen'
</button>
</h2>
<div id="flush-collapseElev1" class="accordion-collapse collapse" aria-labelledby="flush-headingElev1" data-bs-parent="#accordionFlushExample1">
<div class="accordion-body">
<div class="circular_sec">
<img src="Images/workshop11.jpg" alt="LVISGN" class="inner_img"/>
<p>The students of grades II, III, IV & V of Lotus Valley International School Noida Extension had a unique opportunity of interacting with the renowned children's author, actor, director and child rights activist, Ms Nandana Dev Sen in the session 'Author Speak' on 1 August, 2019. The versatile author, daughter of the Nobel Laureate Amartya Sen was warmly welcomed by the school Principal, Ms Indu Yadav, with a sapling. Ms Sen began the session by reading excerpts from her books. She interacted with children and shared experiences from her life that inspired her to write. Replete with life lessons, each story, interestingly narrated, left students clamoring for more. Towards the end of the session, Ms Sen took queries from the students related to book writing. It was an enriching, engaging and fruitful session which helped children expand the horizon of their learning.</p>

</div>
</div>
</div>
</div>
<div class="accordion-item">
<h2 class="accordion-header" id="flush-headingTen1">
<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen1" aria-expanded="false" aria-controls="flush-collapseTen1">
 Workshop on 'ARTiculate' at India International Center
</button>
</h2>
<div id="flush-collapseTen1" class="accordion-collapse collapse" aria-labelledby="flush-headingTen1" data-bs-parent="#accordionFlushExample1">
<div class="accordion-body">
<div class="circular_sec">
<img src="Images/workshop10.jpg" alt="LVISGN" class="inner_img"/>
<p>“The principle of true art is not to portray, but to evoke”- Jerzy Kosinski The teachers attended a one day workshop ‘ARTiculate’ on 17 August 2019 at Indian International Center, New Delhi. The workshop was initiated by Ms. Aditi Misra, former Principal of Delhi Public School, Gurgaon. She did ‘set the tone’ for the workshop by stressing on integration of art in every aspect of school curriculum like learning life skills, language and communication, problem solving etc. She emphasized that for each artist, art should come from the heart and it should work as therapy for all. She stressed on individuality of each artist and various stages of artistic development and concluded by encouraging the teachers to take bolder steps as educators to bring out creative and out of the box thinking in our learners. Ms. Madhumita Nandi and Ms Bhavna Srivastava introduced some activities using simple shapes like circles and triangles, which were converted into various hand toys and classroom education tools. They introduced some innovative hands on activity tools like Tangrams, Micrography, Spoonerism, Droodles, colour scheme to teach symmetry ‘Rangometry’ etc. The workshop was quite informative and added value to the attendees.</p>

</div>
</div>
</div>
</div>
<div class="accordion-item">
<h2 class="accordion-header" id="flush-headingNine1">
<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine1" aria-expanded="false" aria-controls="flush-collapseNine1">
 Celebration of Roald Dahl's birthday and session on storytelling
</button>
</h2>
<div id="flush-collapseNine1" class="accordion-collapse collapse" aria-labelledby="flush-headingNine1" data-bs-parent="#accordionFlushExample1">
<div class="accordion-body">
<div class="circular_sec">
<img src="Images/workshop9.jpg" alt="LVISGN" class="inner_img"/>
<p>“Having power is not nearly as important as what you choose to do with it”- Roald Dahl Storytelling is an ancient and valuable art that extends around the globe. In this unit, students develop their own storytelling talents, apply the techniques of storytelling, create storytelling guides, and perform a story for an audience. LVISNE celebrated the birthday of a very famous author Roald Dahl which was followed by a story telling session by Ms.Sonia Bareja. Ms.Sonia is a versatile, enthusiastic and creative demonstrator cum educator involved in Training & Creative Development of young adults and children. After the session there was a quiz on Roald Dahl’s work. Students enjoyed the session immensely.</p>

</div>
</div>
</div>
</div>
<div class="accordion-item">
<h2 class="accordion-header" id="flush-headingEight1">
<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight1" aria-expanded="false" aria-controls="flush-collapseEight1">
 Workshop on ‘An introductory session on 'Teacher as a Counselor'
</button>
</h2>
<div id="flush-collapseEight1" class="accordion-collapse collapse" aria-labelledby="flush-headingEight1" data-bs-parent="#accordionFlushExample1">
<div class="accordion-body">
<div class="circular_sec">
<img src="Images/workshop8.jpg" alt="LVISGN" class="inner_img"/>
<p>An enriching session on 'Teacher as a Counselor' was conducted by Dr. V.S Ravindran on 24th June, 2019 at LVISNE. It was an interactive session where Dr. Ravindran talked about the different roles of a teacher and how a teacher can be a counselor. A teacher’s role in the past few years has shifted from being a lecturer to a facilitator, a coach and mentor and finally to a counselor who is responsible for the holistic development of a child. A teacher has to don different hats at different times in order to encourage learning as well as build a healthy rapport with the students. He emphasized on the fact that the content is driven by students as well and it is no more teacher-centric. He also said that a supportive atmosphere should be created for every child where every child is respected and valued. A teacher should be friendly with students, but must know where to draw the line. For reinforcing good values and positive behavior in children, teachers should catch them doing the ‘right things’ rather than repeatedly pointing out wrong behavior or mistakes. He regaled the teachers with a lot of anecdotes from his career as an educator, psychologist and counselor, spanning more than 35 years. It was an informative and interactive session with the message- ‘I am not A teacher, I am THE teacher.’</p>

</div>
</div>
</div>
</div>
<div class="accordion-item">
<h2 class="accordion-header" id="flush-headingSeven1">
<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven1" aria-expanded="false" aria-controls="flush-collapseSeven1">
 Workshop on "Mini MBA at School using Hub & Spoke model"
</button>
</h2>
<div id="flush-collapseSeven1" class="accordion-collapse collapse" aria-labelledby="flush-headingSeven1" data-bs-parent="#accordionFlushExample1">
<div class="accordion-body">
<div class="circular_sec">
<img src="Images/workshop7.jpg" alt="LVISGN" class="inner_img"/>
<p>A workshop was conducted for the teachers of LVISNE by Mr. Amit Agarwal, an MBA and corporate professional, on the art of building key soft skills like optimism, innovation, collaboration, future readiness and adaptability. Children, while growing up, develop a comfortable cocoon of family, close friends and playmates. While that is an intrinsic part of growing up, it can sometimes lead to hesitation in interacting with people who are not part of their social group. In order to prevent this social inhibition, children should be given opportunities to interact, communicate and collaborate as a team with people, apart from their social group. The ‘Hub and Spoke’ model advocated by him can facilitate collaboration between students of different calibre and interests. Giving them projects in which, they work and interact towards achieving a common goal can lead to better social, thinking and communication skills. The teachers talked about various collaborative learning strategies which they have been implementing in their classes and the positive results, thus, seen in many students. It was another step towards providing an enriching ecosystem for teacher empowerment.</p>

</div>
</div>
</div>
</div>
<div class="accordion-item">
<h2 class="accordion-header" id="flush-headingSix1">
<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix1" aria-expanded="false" aria-controls="flush-collapseSix1">
 Workshop on ‘Teachers plant seeds that grow forever'
</button>
</h2>
<div id="flush-collapseSix1" class="accordion-collapse collapse" aria-labelledby="flush-headingSix1" data-bs-parent="#accordionFlushExample1">
<div class="accordion-body">
<div class="circular_sec">
<img src="Images/workshop6.jpg" alt="LVISGN" class="inner_img"/>
<p>’The good teacher explains. The superior teacher demonstrates. The great teacher inspires.’’ -William Arthur Ward. An enriching and interactive workshop on “Teachers plant seeds that grow forever’’ was conducted for teachers on June 27th, 2019 at LVISNE. The workshop was spearheaded by Ms. Usha Pathania, Director-Academics at Mahattattva Education Advisory which empowers schools across the nation. The session aimed at encouraging and motivating the teacher-facilitators to bring in innovations in teaching-learning process. The workshop focused at enhancing the teaching strategies in classrooms for an effective and lifelong learning. It emphasised at enhancing the 21st century skills of students, engaging them in a learning process that goes beyond textbook and create a conducive learning environment for students to find a solution to problems in order to bring about positive changes for a sustainable future. The workshop encompassed a number of activities that were done during the session. The activities were performed by all teachers, like Role Playing, Magazine Cover Making, Jingles, and Street Play. The purpose of the workshop was to ensure that everyone participated in order to understand any concept – broadly, the workshop focused on the idea of Art Integration. The multidisciplinary approach to teaching allows for a better learning outcome thereby, making the child make connect with the outside world and making teaching a meaningful exercise.</p>

</div>
</div>
</div>
</div>
<div class="accordion-item">
<h2 class="accordion-header" id="flush-headingFive1">
<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive1" aria-expanded="false" aria-controls="flush-collapseFive1">
 Workshop on ‘The Art of Story-Telling- Session with Ms. Simmi Srivastava'
</button>
</h2>
<div id="flush-collapseFive1" class="accordion-collapse collapse" aria-labelledby="flush-headingFive1" data-bs-parent="#accordionFlushExample1">
<div class="accordion-body">
<div class="circular_sec">
<img src="Images/workshop5.jpg" alt="LVISGN" class="inner_img"/>
<p>The story-telling session with Ms. Simmi Srivastava was held on 9 August, 2019 and Teachers across all grades from Pre-Nursery to Grade 4 were in attendance. Ms. Simmi's session highlighted the need to implement story-telling in the classrooms, to transform and enliven the teaching-learning process. The session began with Ms. Simmi narrating the ‘Hungry Caterpillar’ complete with expressions and intonation. It was a surprise to see that although the story is generally for pre-primary students, it could also be woven around topics and concepts usually difficult to understand. Most teachers expressed concern for not being able to hold a student's attention for more than a short span of time. However, Ms. Simmi's narration of 'Geet ka Kamal', was a clear example of how involvement of the students in a story telling session is guaranteed to improve the attention. Lastly, the session brought home the point that story-telling also caters to all three types of learning styles - kinaesthetic, auditory and visual, which is why the lesson or the concept being taught is better absorbed and understood. ‘As educators, we are only as effective as what we know.’ Rightly said, teachers are also learners, and they need to continually hone their skills, improve and implement new tools to make teaching more effective.</p>

</div>
</div>
</div>
</div>
<div class="accordion-item">
<h2 class="accordion-header" id="flush-headingFour1">
<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour1" aria-expanded="false" aria-controls="flush-collapseFour1">
 Workshop on ‘Workshop on Critical and Creative Thinking'
</button>
</h2>
<div id="flush-collapseFour1" class="accordion-collapse collapse" aria-labelledby="flush-headingFour1" data-bs-parent="#accordionFlushExample1">
<div class="accordion-body">
<div class="circular_sec">
<img src="Images/workshop4.jpg" alt="LVISGN" class="inner_img"/>
<p>"You can't use up creativity. The more you use, the more you have"- Maya Angelou Lotus Valley International School, Noida Extension focuses on the overall development of students. The endeavor is to create curious minds and provide them with the means to ignite creative and independent thinking. For this, an interactive and informative session was conducted by Ms. Suhasini Kanwar on 25th June 2019. Ms Suhasini holds experience in training teachers to match up with the constantly changing teaching strategies. The session focused on multiple intelligences and collaborative strategies that can be implemented in classrooms and to achieve effective learning for the students. The session also threw light on critical and creative thinking, how it can be inculcated and encouraged in students and integrated with the curriculum through activity-based learning. The session was centred around facilitating teachers with practical examples and strategies that can be incorporated in the classroom setting to make learning more effective, interactive and impactful.</p>

</div>
</div>
</div>
</div>
<div class="accordion-item">
<h2 class="accordion-header" id="flush-headingThree1">
<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree1" aria-expanded="false" aria-controls="flush-collapseThree1">
 Workshop on ‘Workshop on Story Telling - Once Upon A Time'
</button>
</h2>
<div id="flush-collapseThree1" class="accordion-collapse collapse" aria-labelledby="flush-headingThree1" data-bs-parent="#accordionFlushExample1">
<div class="accordion-body">
<div class="circular_sec">
<img src="Images/workshop3.jpg" alt="LVISGN" class="inner_img"/>
<p>A workshop on Story Telling - Once Upon A Time with participants from various schools on September 14, 2019 was being organised at Lotus Valley International School, Noida which was attended by Ms. Surbhi Juneja and Ms. Nidhi Jasrai from Lotus Valley International School, Noida Extension. An interesting session on playing with stories was conducted by Ms. Nupur Aggarwal. It focused on how to spark imagination, build vocabulary, ingrain values and build a connection with the children through stories. The session gave a valuable insight on how a story comes alive through sounds, actions, voice modulation, props and repetition of phrases.</p>

</div>
</div>
</div>
</div>
<div class="accordion-item">
<h2 class="accordion-header" id="flush-headingTwo1">
<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo1" aria-expanded="false" aria-controls="flush-collapseTwo1">
 Workshop on ‘Session on Positive Psychology' 
</button>
</h2>
<div id="flush-collapseTwo1" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo1" data-bs-parent="#accordionFlushExample1">
<div class="accordion-body">
<div class="circular_sec">
<img src="Images/workshop2.jpg" alt="LVISGN" class="inner_img"/>
<p>A workshop was held at Lotus Valley International School, Noida Extension on 'Positive Psychology - an introduction to your best version'' by team members of Youniverse, Mr. Sanjeevan Sandhu and Ms. Tanya Bakshi. The resource persons emphasized on mental health for every individual to save them from getting into anxiety and depression. Passion and Purpose were talked about and it was reiterated that ‘Passion’ is what we do solely for ourselves while ‘Purpose’ is when we add value to the lives of others. Also, Emotional Evaluation done everyday ensures that we do not hold grudges for a very long time because it ultimately ends up harming our own mental health. Mr. Sanjeevan & Ms. Tanya also talked about the importance of growth mindset which is ideally what everyone should practice and how it works on positive, problem-solving, optimistic thoughts. They also emphasized practicing gratitude in our everyday lives in order to feel more positive and rejuvenated. The workshop ended on a positive note with a vote of thanks by the Principal, Ms. Indu Yadav.</p>

</div>
</div>
</div>
</div>
<div class="accordion-item">
<h2 class="accordion-header" id="flush-headingOne1">
<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne1" aria-expanded="false" aria-controls="flush-collapseOne1">
 Workshop on 'Session on Empathy by Goonj Foundation' 
</button>
</h2>
<div id="flush-collapseOne1" class="accordion-collapse collapse" aria-labelledby="flush-headingOne1" data-bs-parent="#accordionFlushExample1">
<div class="accordion-body">
<div class="circular_sec">
<img src="Images/workshop1.jpg" alt="LVISGN" class="inner_img"/>
<p>"The world has enough for everyone's needs, but not everyone's greed" -Mahatma Gandhi .As the part of the social surrounding, the Lotus Family considers it a duty to give as much back to the society as is taken from them. For this, Lotus Valley International School, Noida Extension hosted a team from Goonj Foundation on September 11, 2019 to conduct a session on Empathy. The interactive session was conducted for Classes IV and V to generate awareness about the difficulties that the underprivileged children in the rural and urban areas have to face to make ends meet. The students were also encouraged to not discard their belongings but to contribute and share them. The session was concluded with a message and a promise of trying to make a difference to the lives of those who actually need it.</p>

</div>
</div>
</div>
</div>
</div>
</TabPanel>
           </Tabs>
        </div>
    </div>
     <Footer/>
    </>
  )
}

export default TrainingTrainers
