import React, { useEffect } from 'react'
import InnerHaeder from '../Component/InnerHaeder'
import Highlights from '../Component/Highlights'
import Footer from'../Component/Footer'
import { Link } from 'react-router-dom'
import HeadTag from '../Component/HeadTags'
const Contact = () => {
  useEffect(() => {
    // Set the page title
    document.title = 'Contact - Lotus Valley Greater Noida'; // Change 'Your Website Name' to your actual website name
  }, []);
  return (
    <>
    <HeadTag title="Contact" /> 
     <Highlights/>
     <InnerHaeder/>
     <div className="inner_page">
       <div className="container-fluid flwidth contact_page">
       <div className="bgimage">
        <h5><Link to="/"> Home <i className="fas fa-angle-right"></i> </Link><span> Contact </span></h5>
        <h2> Contact </h2>
        </div>
        <Link to="https://www.lviscampuscare.net/Registration/OnlineEnquiry" target="_blank"><img className="enquiry-form" src="Images/enquiery.png" alt="Lotus Valley International School, Greater Noida"/></Link>
        <div className="clr10"></div>
        <div className="clr5"></div>
           <div className="row">
           <div className="col-lg-6 col-md-12">
          <h3><strong> Lotus Valley International School</strong> </h3>
         <div className="clr10"></div>
         <i className="fa fa-map-marker" aria-hidden="true"></i>
         <p><Link to="https://maps.app.goo.gl/1k1F4267KCUTR8vDA" target="_blank">Plot 20 A, Sector-Tech Zone-4, Greater Noida West (Noida Extension) UP- 201306</Link></p>
         
         <h3><strong>FOR TRANSPORT</strong></h3>
         <div className="clr10"></div>
         <i className="fa fa-phone"></i>
         <h3>Phone</h3>
         <p><Link to="tel:9205756356"> 8448992083</Link> , <strong>Landline  No :</strong> <Link to="tel:O1204844770">O1204844770 </Link></p>
         <div className="clr5"></div>
         <i className="fa fa-envelope" aria-hidden="true"></i>
         <h3>Email</h3>
         <p><Link to="mailto:transport@lotusvalleynoidaextension.com">transport@lotusvalleynoidaextension.com</Link><br />
          <Link to="mailto:estate@lotusvalleynoidaextension.com">estate@lotusvalleynoidaextension.com</Link></p>
           <div className="clr10"></div>
          
         <h3><strong>FOR RECRUITMENT</strong></h3>
         <div className="clr10"></div>
         <i className="fa fa-phone"></i>
         <h3>Phone</h3>
         <p><Link to="tel:9205992385"> 9205992385</Link> </p>
         <div className="clr5"></div>
         <i className="fa fa-envelope" aria-hidden="true"></i>
         <h3>Email</h3>
         <p><Link to="mailto:hr@lotusvalleynoidaextension.com">hr@lotusvalleynoidaextension.com</Link> </p>
         <div className="clr10"></div>
         </div>
         <div className="col-lg-6 col-md-12">
         <h3><strong> FOR ADMISSIONS</strong></h3>
         <div className="clr5"></div>
         <i className="fa fa-phone"></i>
         <h3>Phone</h3>
         <p><Link to="tel:9821694492"> 9821694492</Link> / <Link to="tel:8448992087">8448992087</Link>  , <strong>Landline  No :</strong> <Link to="tel:O1204844771">O1204844771 </Link></p>
         <div className="clr5"></div>
         <i className="fa fa-envelope" aria-hidden="true"></i>
         <h3>Email</h3>
         <p><Link to="mailto:admissions@lotusvalleynoidaextension.com"> admissions@lotusvalleynoidaextension.com</Link><br />
           <Link to="mailto:enquiry@lotusvalleynoidaextension.com"> enquiry@lotusvalleynoidaextension.com</Link></p>
           <div className="clr10"></div>
         <h3><strong>FOR APPOINTMENT WITH PRINCIPAL</strong></h3>
         <div className="clr10"></div>
         <i className="fa fa-phone"></i>
         <h3>Phone</h3>
         <p><Link to="tel:9205992385"> 9205992385</Link></p>
         <div className="clr5"></div>
         <i className="fa fa-envelope" aria-hidden="true"></i>
         <h3>Email</h3>
         <p><Link to="mailto:principal@lotusvalleynoidaextension.com">principal@lotusvalleynoidaextension.com</Link><br />
             <Link to="mailto: info@lotusvalleynoidaextension.com"> info@lotusvalleynoidaextension.com</Link></p>
             <div className="clr"></div>
             <p><b>Reception-Landline  No :</b><Link to="tel:O1204844772"> O1204844772</Link><br />
         <b>Accounts-Landline  No :</b> <Link to="tel:O1204844773"> O1204844773</Link><br />
             <b> Medical-Landline  No :</b> <Link to="tel:O1204844774"> O1204844774</Link><br /> </p>
         </div>
           </div>
        </div>
        </div>
     <Footer/>
    </>
  )
}

export default Contact
