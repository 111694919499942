import React from 'react'
import InnerHaeder from '../Component/InnerHaeder'
import Highlights from '../Component/Highlights'
import Footer from'../Component/Footer'
import { Link } from 'react-router-dom'
import HeadTag from '../Component/HeadTags'
const AssessmentEvaluation = () => {
  return (
    <>
    <HeadTag title="Assessment & Evaluation" />
     <Highlights/>
     <InnerHaeder/>
     <div className="inner_page">
       <div className="container-fluid flwidth">
       <div className="bgimage">
        <h5><Link to="/"> Home <i className="fas fa-angle-right"></i> </Link><span>  Academics <i className="fas fa-angle-right"></i>  Assessment & Evaluation </span></h5>
        <h2> Assessment & Evaluation </h2>
        </div>
        <div className="row">
            <div className="col-lg-12 col-md-12">
            <h3>CLASSES I-V</h3>
            <p>The academic session is divided into two semesters.</p>      
            <p><b>First Term:</b> April to September</p>
            <p><b>Second Term:</b> October to March</p>
            <ul>  
            <li> The school follows the curriculum designed by the NCERT.</li>
            <li> No formal examination is held for classes I-V. However, weekly assessments are conducted to evaluate the progress of the child.</li>
            </ul>
            <h3>CLASSES VI - X</h3>
            <ul>  
            <li> The session is divided into Pre Mid Term, Mid Term, Post Mid Term and Year End Assessment.</li>
            <li> Annual exam will be given 80% weightage.</li>
            <li> 20% marks will be based on Periodic Tests, Portfolio, Multiple Assessments and Subject Enrichment Activities.</li>
            <li> In order to ensure holistic development of the students the co-scholastic activities will also be graded on five point scale (A to E).</li>
            <li> Discipline significantly impacts career shaping and it helps build character. Thus, discipline (attendance, sincerity, behaviour, values) will be graded on a five point scale.</li>
            <li> It is mandatory for the students to give all the Periodic Tests</li>
            </ul>
            </div>
        </div>
        </div>
    </div>
     <Footer/>
    </>
  )
}

export default AssessmentEvaluation
