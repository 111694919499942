import React from 'react'
import InnerHaeder from '../Component/InnerHaeder'
import Highlights from '../Component/Highlights'
import Footer from '../Component/Footer'
import { Link } from 'react-router-dom'
import HeadTag from '../Component/HeadTags'
const Sports = () => {
  return (
    <>
      <HeadTag title="Sports" />
      <Highlights />
      <InnerHaeder />
      <div className="inner_page">
        <div className="container-fluid flwidth">
          <div className="bgimage">
            <h5><Link to="/"> Home <i className="fas fa-angle-right"></i> </Link><span> Co-Curricular  <i className="fas fa-angle-right"></i> Sports </span></h5>
            <h2>Sports</h2>
          </div>
          <p>Physical fitness is one of the most important elements of leading a healthy life. Physical education promotes the importance of inclusion of a regular fitness activity in the routine. This helps the students to maintain their fitness, develop their muscular strength, increase their stamina and thus stretch their physical abilities to an optimum level. Sports help inculcate discipline, self confidence, team spirit, sense of belonging, pride, achievement and self esteem. Students also understand that victory and defeat is an ongoing process. </p>

          <div className="row img_data_sec">
            <div className="col-lg-4 col-md-6"><div className="box"> <a data-magnify="gallery" data-src="" data-group="a" href="Images/BADMINTON.jpg">
              <img src="Images/BADMINTON.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" /></a>
              <h4>BADMINTON</h4></div></div>
            <div className="col-lg-4 col-md-6"><div className="box"> <a data-magnify="gallery" data-src="" data-group="a" href="Images/FOOTBALL.jpg">
              <img src="Images/FOOTBALL.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" /></a>
              <h4>FOOTBALL</h4></div></div>
            <div className="col-lg-4 col-md-6"><div className="box"> <a data-magnify="gallery" data-src="" data-group="a" href="Images/TABLE TENNIS.jpg">
              <img src="Images/TABLE TENNIS.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" /></a>
              <h4>TABLE TENNIS</h4></div></div>

            <div className="col-lg-4 col-md-6"><div className="box"> <a data-magnify="gallery" data-src="" data-group="a" href="Images/SKATING.jpg">
              <img src="Images/SKATING.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" /></a>
              <h4>SKATING</h4></div></div>
            <div className="col-lg-4 col-md-6"><div className="box"> <a data-magnify="gallery" data-src="" data-group="a" href="Images/YOGA.jpg">
              <img src="Images/YOGA.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" /></a>
              <h4>YOGA</h4></div></div>
            <div className="col-lg-4 col-md-6"><div className="box"> <a data-magnify="gallery" data-src="" data-group="a" href="Images/Taekwondo.jpg">
              <img src="Images/Taekwondo.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" /></a>
              <h4>Taekwondo</h4></div></div>
            <div className="col-lg-4 col-md-6"><div className="box"> <a data-magnify="gallery" data-src="" data-group="a" href="Images/CRICKET.jpg">
              <img src="Images/CRICKET.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" /></a>
              <h4>CRICKET</h4></div></div>
            <div className="col-lg-4 col-md-6"><div className="box"> <a data-magnify="gallery" data-src="" data-group="a" href="Images/CHESS.jpg">
              <img src="Images/CHESS.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" /></a>
              <h4>CHESS</h4></div></div>

          </div>
          <h2>Sports Achievements</h2>
          <h3><center><b>REPORT PRIMARY WING - 2024 </b></center></h3>
          <div className="row acheivement_2024">
            <div className='col-md-4'>
              <div className="img-block">
                <img src="/Images/primary-wing-table.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
              </div>
            </div>
            <div className='col-md-8'>
              <h3>TABLE TENNIS</h3>
              <p><b>SCHOOL: </b>INDRAPRASTHA GLOBAL SCHOOL, NOIDA</p>
              <p><b>Event: </b>Khel Utsav</p>
              <p>We are thrilled to announce the outstanding achievements of our talented students in a recent Table Tennis competition.</p>
              <p>*Pranay Jain from IV Nalanda secured the 2nd runner-up position in U-11 Boys category, showcasing exceptional skills and dedication.</p>
              <p>*Garvita Saxena from V Kalam achieved the 2nd position in U-11 Girls category, demonstrating remarkable talent and hard work.</p>
            </div>
          </div>
          <div className="row acheivement_2024">
            <div className='col-md-4'>
              <div className="img-block">
                <img src="/Images/swiming-tournament.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
              </div>
            </div>
            <div className='col-md-8'>
              <h3>SWIMMING</h3>
              <p><b>SCHOOL: </b>LPS Global school</p>
              <p>Congratulations to Aarav Koul, a grade five student from Lotus Valley International School Noida extension for securing 2nd place in the 50 m freestyle event at the Inter School swimming tournament hosted by LPS Global School Noida competing against 35 schools from Delhi NCR are impressive performance has brought great honour to our school.</p>
              <p>The entire Lotus Valley International School community extends heartfelt congratulations to Aarav for his outstanding achievement. Well done, Aarav!</p>
            </div>
          </div>
          <div className="row acheivement_2024">
            <div className='col-md-4'>
              <div className="img-block">
                <img src="/Images/swiming-tournament.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
              </div>
            </div>
            <div className='col-md-8'> 
              <p><b>SCHOOL: </b> DWPS, GREATER NOIDA</p>
              <p><b>Event: </b>SWIMMING TOURNAMENT</p>
              <p><i><b>‘Winners always find a way’</b></i></p>
              <p>We're absolutely thrilled to share that our young lotusian Aarav Koul of Grade V Edison has secured the Second position in 25 mtr freestyle event at the inter-school Swimming Tournament which was held at DWPS Greater Noida.</p>
              <p>Huge congratulations to Aarav Koul for bringing laurels and pride to school.</p>
            </div>
          </div>

          <div className="row acheivement_2024">
            
            <div className='col-md-12'> 
            <h3>FOOTBALL</h3>
              <p><b>SCHOOL: </b>Bharat Ram Global School, Greater Noida</p>
              <p><b>Date: </b>May, 18th, 2024</p>
              <p><b>Position: </b>Silver Medal</p> 
              <p><i><b>‘Success is no accident. It is hard work, perseverance, learning, studying, sacrifice and most of all, love of what you are doing. – Pelé’</b></i></p>
              <p>With remarkable agility, precision passing, and strategic gameplay, the young players clinched the second position in the fiercely contested interschool football match held at Bharat Ram Global School, Greater Noida.</p>
              <p>Congratulations to our team of young lotusians.</p>
            </div>
          </div>
          <div className="row acheivement_2024">
            <div className='col-md-4'>
              <div className="img-block">
                <img src="/Images/yoga-img.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
              </div>
            </div>
            <div className='col-md-8'> 
            <h3>YOGA:</h3>
              <p><b>EVENT: </b>Uttar Pradesh sports Association.</p>
              <p><b>Date: </b>August, 10, 2024</p> 
             
            </div>
          </div>

          <div className="row acheivement_2024">
            <div className='col-md-4'>
              <div className="img-block">
                <img src="/Images/yoga-champion.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
              </div>
            </div>
            <div className='col-md-8'>  
              <p><b>SCHOOL: </b>Bharat Ram Global School</p>
              <p><b>EVENT: </b>Yoga Championship</p>
              <p><b>Date: </b>17-05-2024</p> 
              <p><i><b>‘Winners are not the people who never fail but who never quit'</b></i></p>
              <p>Displaying their remarkable skill and focus, the young learners of Lotus Valley International School, Noida Extension fervently participated in an Inter-School Yoga Championship held at Bharat Ram Global School. With their exceptional agility in asanas, transitions, and group synchronization, the yogis secured top positions in several categories.</p>
              <p>Congratulations to all the participants.</p>
            </div>
          </div>


          <div className="row acheivement_2024">
            <div className='col-md-4'>
              <div className="img-block">
                <img src="/Images/taekwondo-img.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
              </div>
            </div>
            <div className='col-md-8'>  
            <h3>TAEKWONDO</h3> 
              <p><i><b>Uttar Pradesh Taekwondo Championship</b></i></p>
              <p>We are delighted to share the outstanding achievements of our students at the 3rd Uttar Pradesh State Taekwondo Championship 2024. Our talented athletes have brought home a total of 12 medals: 3 Gold, 1 Silver, and 8 Bronze.</p>
            </div>
          </div>
          <div className="row acheivement_2024">
            
            <div className='col-md-12'>  
            <p><b>SCHOOL:</b> INDRAPRASTHA GLOBAL SCHOOL</p>
            <p><b>EVENT:</b> : KHEL UTSAV</p>
            <p><b>DATE:</b> : 10TH August, 2024</p>
              <p><i><b>‘Victory comes from perseverance, skill, and unwavering dedication.’</b></i></p>
              <p>With their remarkable talent and determination, the young taekwondo champions of Lotus Valley International School, NE fervently participated in Inter-School sports meet Khel Utsav held at Indraprastha</p>
<p>Global School. With unparalleled skill and unwavering determination, they emerged victorious, showcasing excellence in every bout.</p>
<p>Congratulations to the Champions.</p>
            </div>
          </div>
          <div className="row acheivement_2024">
             
            <div className='col-md-12'>  
            <h3>DISTRICT MALAKPUR STATE COMPETITION</h3>  
              <p><b>Every accomplishments start with the decision to try.</b></p>
              <p>As testament to their dedication, discipline, and passion for the sport, our young champions of taekwondo clinched victory in the fiercely contested IN 4th Gautam Buddha District Taekwondo Championship held at MALAKPUR INDOOR STADIUM, GR. NOIDA. With unparalleled skill and unwavering determination, they emerged victorious, showcasing excellence in every bout.</p>
              <p>Congratulations to our extraordinary Taekwondo champions!</p>
              <ul>
                <li>AHANA BHATNAGAR - III SHIVAJI, BRONZE MEDAL</li>
                <li>SALAAR SAEED KHAN -IV HARVARD GOLD MEDAL</li>
                <li>NAKSH ANAND IV GEORGIA, GOLD MEDAL</li>
                <li>ARYA TIWARI, V DARWIN, SILVER MEDAL</li>
                <li>ADVIK KUMAR SINGH, V KALAM, GOLD MEDAL</li>
              </ul>
<p>Congratulations to the Champions.</p>
            </div>
          </div>

          <div className="row acheivement_2024">
          <div className='col-md-12'>  
            <h3>CHESS</h3> 
            
              <p><i><b>‘Winners are not the people who never fail but who never quit'</b></i></p>
              <p>It's a matter of great pride for us as our young chess players participated in Pratispardha, Chess championship on May 15,2024 at LVIS,126 under the mentorship of Mr Bhupendra.</p>
              <p>Our learners have proved themselves through their exceptional endeavours and bagged bronze medal in the championship.</p>
<p>Congratulations to the Champions.</p>
            </div>
            <div className='col-md-6'>
              <div className="img-block">
                <img src="/Images/chess-winner1.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
              </div>
            </div>
            <div className='col-md-6'>
              <div className="img-block">
                <img src="/Images/chess-winner2.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" />
              </div>
            </div>
          
          </div>

          <div className="row acheivement_2024">
             
             <div className='col-md-12'>  
             <h3>SKATING</h3>  
               <p><b>STATE ROLLER SPORTS CHAMPIONSHIP</b></p>
               <p>We are thrilled to announce our triumphant achievement of clinching the various positopns in State Roller Skating Championship. Our school glided into the spotlight at the skating competition, adorning itself with laurels and securing medals across diverse categories :</p>
               <ul>
                 <li>Anaaya Singhal – III Alexander, Gold and Silver medal</li>
                 <li>Anaaya Thakral – V Hawking, Silver medal</li>
                 <li>Abhigyan Shekhar – V Hawking, Gold and Silver medal</li>
                 <li>Shreyansh Singh – V Einstein, Gold medal</li>
                 <li>Pranika Nagar – III Napoleon, Gold and Silver medal</li>
                 <li>Reyansh Singhi – III Maurya, Bronze medal</li>
               </ul>
 <p>Congratulations to the Champions.</p>
             </div>
           </div>











          <h3><center><b>REPORT ON YOGA CHAMPIONSHIP - 2020</b></center></h3>
          <p><strong>“The Key is not the will to win...everybody has that. It is the will to prepare to win that is important.”</strong></p>
          <p>Our students displayed their Flexibility, Stability & Talent at Concurrence 2020, Online Yoga Sports Competition, in association with UP State Yoga Association was Organsied by Jaypee Public School, Greater Noida on 28 - 29 August 2020 and Around 60 schools from UP had participated in this event. The students of LVISNE, brought laurels to the school by winning 1 silver and 2 bronze medals in different age categories.</p>
          <div className="row">
            <div className="col-md-12">
              <div className="innertable">
                <table width="100%" cellpadding="0" cellspacing="0" border="0">
                  <thead>
                    <tr>
                      <th>S. No.</th>
                      <th>Name</th>
                      <th>Class</th>
                      <th>Age Group Category</th>
                      <th>Position or Participation Certificate</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1. </td>
                      <td>Aardhya Chauhan</td>
                      <td>KG - Jasmine</td>
                      <td>Under 5 - Girls</td>
                      <td>Participation Certificate</td>
                    </tr>
                    <tr>
                      <td>2. </td>
                      <td>Atharva Sharma</td>
                      <td>KG - Sunflower</td>
                      <td>Under 5 - Boys</td>
                      <td>Participation Certificate</td>
                    </tr>
                    <tr>
                      <td>3. </td>
                      <td>Anushka Gupta</td>
                      <td>Class 1 Flamingo</td>
                      <td>Under 7 - Girls</td>
                      <td>Participation Certificate</td>
                    </tr>
                    <tr>
                      <td>4. </td>
                      <td>Abhigyan Shekhar</td>
                      <td>Class 1 Dove</td>
                      <td>Under 7 - Boys</td>
                      <td>Participation Certificate</td>
                    </tr>
                    <tr>
                      <td>5. </td>
                      <td>Yashika Dubey</td>
                      <td>class 3 Augustus</td>
                      <td>Under 9 - Girls</td>
                      <td>Bronze</td>
                    </tr>
                    <tr>
                      <td>6. </td>
                      <td>Vyom Gupta</td>
                      <td>Class 3 Akbar</td>
                      <td>Under 9 - Boys</td>
                      <td>Participation Certificate</td>
                    </tr>
                    <tr>
                      <td>7. </td>
                      <td>Laasya Raghuvanshi</td>
                      <td>Class 6 Aries</td>
                      <td>Under 11 - Girls</td>
                      <td>Silver</td>
                    </tr>
                    <tr>
                      <td>8. </td>
                      <td>Shaurya Pratap Pathak</td>
                      <td>Class 5 Darwin</td>
                      <td>Under 11 - Boys</td>
                      <td>Participation Certificate</td>
                    </tr>
                    <tr>
                      <td>9. </td>
                      <td>Navika Sharma</td>
                      <td>Class 7 Marx</td>
                      <td>Under 13 - Girls</td>
                      <td>Bronze</td>
                    </tr>
                    <tr>
                      <td>10. </td>
                      <td>Nishchal Bhardwaj</td>
                      <td>Class 7 Seneca</td>
                      <td>Under 13 - Boys</td>
                      <td>Participation Certificate</td>
                    </tr>
                    <tr>
                      <td>11. </td>
                      <td>Kritika Sharma</td>
                      <td>Class 9 Maple</td>
                      <td>Under 15 - Girls</td>
                      <td>Participation Certificate</td>
                    </tr>
                    <tr>
                      <td>12. </td>
                      <td>Amitoj Singh</td>
                      <td>Class 8 Ramanujan</td>
                      <td>Under 15 - Boys</td>
                      <td>Participation Certificate</td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="clr"></div>
          <h3><center><b>SKATING CHAMPIONS OF LVIS, NE - 2019</b></center></h3>
          <p>“Victory belongs to those that believe in it the most and believe in it the longest.” Randall Wallace The Skating Champions of LVIS, Noida Extension brought laurels to the school by winning 4 gold, 1 silver, 4 bronze medals in different age categories and secured overall 3rd Position in the Inter-school Skating Championship organized by Azad Roller Sports Academy on Thursday 25, April 2019 at Chilla Sports Complex, Vasundhara Enclave Delhi. Around 30 schools participated from Delhi- NCR region.</p>

          <div className="clr"></div>
          <h3>YOGIS OF LVIS, NE BROUGHT LAURELS TO THE SCHOOL - 2019</h3>
          <p> The University of Delhi, North Campus, organized a 'Navyug Delhi Yoga Championship' in their Conference Center Hall, on 24th and 25th April 2019. Amidst the various participants, our school exhibited its presence with 29 students who participated energetically in the team and individual events. Our students participated with enthusiasm and zeal and brought laurels to the school by winning 3 gold, 4 silver and 2 bronze medals in different age categories. Our children bagged the 1st RUNNER UP trophy in the the championship. Our school witnessed a proud moment when Vyom Gupta, our student of class II- Ganges, won the Title Award of 'Mr. NAVYUG YOGI'. It was a great learning experience and a memorable moment for LVIS, Noida Extension. A complete blend of values and teamwork paved a path for us to achieve this applaud.</p>

        </div>
      </div>
      <Footer />
    </>
  )
}

export default Sports
